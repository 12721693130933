export const menu = [
    {
        "label": "Gaming Management",
        "subs": [
            {
                "label": "Games",
                "icon": "bx bx-dice-5",
                "level1": [
                    {
                        "label": "Create Games",
                        "link": "/form?feature=games&index=0&main=0&level=0",
                        endpoint: '/games',
                        form: [
                            { datatype: "text", value: "name", label: "Game Name", prefix: "" },
                            { datatype: "number", value: "minStake", label: "Minimum Stake", prefix: "GHS " },
                            { datatype: "number", value: "maxStake", label: "Maximum Stake", prefix: "GHS " },
                            { datatype: "number", value: "minNumber", label: "Minimum Number" },
                            { datatype: "number", value: "maxNumber", label: "Maximum Number" },
                            { datatype: "text", value: "drawFrequency", label: "Draw Frequency" },
                            { datatype: "text", value: "cronSchedule", label: "Cron Schedule" },
                            { datatype: "number", value: "payoutThreshold", label: "Payout Threshold" },
                            { datatype: "number", value: "multiplier", label: "Multiplier" },
                            {
                                datatype: "select", value: "winSelection", label: "Win Selection", "source": "link",
                                "url": "/winSelection", keys: ["id", "name"]
                            },
                            { datatype: "text", value: "callbackUrl", label: "Callback Url" },
                            { datatype: "text", value: "paymentGateway", label: "Payment Gateway Url" },
                            { datatype: "number", value: "splitBlocks", label: "Split Blocks" },
                            { datatype: "number", value: "displayOrder", label: "Display Order" },
                            { datatype: "text", value: "shortcode", label: "ShortCode" },
                            { datatype: "text", value: "game_header", label: "Game Header" },
                            { datatype: "text", value: "sms_sender", label: "SMS Sender" },
                            {
                                datatype: "select", value: "status", label: "Status", "source": "object",
                                "url": "", data: [
                                    { value: 0, label: 'Inactive' },
                                    { value: 1, label: 'Active' }
                                ], keys: ["id", "value"]
                            },
                            {
                                datatype: "select", value: "wallet", label: "Wallet", "source": "link",
                                "url": "/wallets", keys: ["id", "name"]
                            },
                        ],
                        actions: []
                    },
                    {
                        "label": "Manage Games", "link": "/grid?feature=games&index=0&main=0&level=1", form: [
                            { datatype: "text", value: "name", label: "Game Name" },
                            { datatype: "number", value: "displayOrder", label: "Display Order" },
                            { datatype: "number", value: "minStake", label: "Minimum Stake", prefix: "GHS " },
                            { datatype: "number", value: "maxStake", label: "Maximum Stake", prefix: "GHS " },
                            { datatype: "number", value: "minNumber", label: "Minimum Number" },
                            { datatype: "number", value: "maxNumber", label: "Maximum Number" },
                            { datatype: "text", value: "drawFrequency", label: "Draw Frequency" },
                            { datatype: "text", value: "cronSchedule", label: "Cron Schedule" },
                            { datatype: "number", value: "payoutThreshold", label: "Payout Threshold" },
                            { datatype: "number", value: "multiplier", label: "Multiplier" },
                            { datatype: "text", value: "shortcode", label: "shortCode" },
                            { datatype: "text", value: "game_header", label: "Game Header" },
                            { datatype: "text", value: "sms_sender", label: "SMS Sender" },
                            {
                                datatype: "select", value: "winSelection", label: "Win Selection", "source": "link",
                                "url": "/winSelection", keys: ["id", "name"]
                            },
                            {
                                datatype: "select", value: "wallet", label: "Wallet", "source": "link",
                                "url": "/wallets", keys: ["id", "name"]
                            },
                            { datatype: "text", value: "callbackUrl", label: "Callback Url" },
                            { datatype: "text", value: "paymentGateway", label: "Payment Gateway Url" },
                            {
                                datatype: "select", value: "status", label: "Status", "source": "object",
                                "url": "", data: [
                                    { value: 0, label: 'Inactive' },
                                    { value: 1, label: 'Active' }
                                ], keys: ["id", "value"]
                            }
                        ], endpoint: '/games',
                        actions: ["edit", "delete"]
                    },
                    {
                        "label": "Games SMS", "link": "/grid?feature=smstemplate&index=0&main=0&level=2", form: [
                            {
                                datatype: "select", value: "gameID", label: "Game", "source": "link",
                                "url": "/games", keys: ["id", "name"]
                            },
                            {
                                datatype: "select", value: "smsType", label: "SMS Type", "source": "link",
                                "url": "/smstypes", keys: ["id", "id"]
                            },
                            { datatype: "textarea", value: "template", label: "SMS Body" },
                        ], endpoint: 'smstemplate',
                        actions: ["edit"]
                    }
                ]
            }, {
                "label": "Tickets",
                "icon": "bx bx-analyse",
                "level1": [
                    {
                        "label": "Game Tickets",
                        "link": "/grid?feature=games&index=1&main=0&level=0",
                        form: [
                            { datatype: "text", value: "id", label: "Ticket ID" },
                            { datatype: "text", value: "mobileNumber", label: "Mobile number" },
                            { datatype: "text", value: "status", label: "status" },
                            { datatype: "text", value: "playedNumbers", label: "Played Numbers", },
                            { datatype: "text", value: "stake", label: "Stake", prefix: "GHS " },
                            { datatype: "text", value: "payoutAmount", label: "Payout", prefix: "GHS " },
                            { datatype: "date", value: "playTimestamp", label: "Timestamp" },
                            { datatype: "text", value: "mobileNetwork", label: "Network" },
                        ],
                        endpoint: 'tickets1',
                        actions: ["viewticket"]
                    }
                ]
            }, {
                "label": "Draws",
                "icon": "bx bx-analyse",
                "level1": [
                    {
                        "label": "Game Draws",
                        "link": "/grid?feature=games&index=2&main=0&level=0",
                        form: [
                            { datatype: "text", value: "id", label: "Draw ID" },
                            { datatype: "text", value: "winningNumbers", label: "Winning number" },
                            { datatype: "text", value: "collections", label: "Collections" },
                            { datatype: "text", value: "drawCollections", label: "Draw Collections" },
                            { datatype: "text", value: "totalPlays", label: "Total Plays" },
                            { datatype: "date", value: "drawTimestamp", label: "Timestamp" },
                            { datatype: "text", value: "totalPayout", label: "Total Payout" },
                            { datatype: "text", value: "payoutCount", label: "Payout Count" },
                            { datatype: "text", value: "drawPayout", label: "Draw Payout" },
                        ],
                        endpoint: 'draws',
                        actions: ["details"]
                    }
                ]
            }
        ]
    }, {
        "label": "Settings",
        "subs": [
            {
                "label": "USSD Menu",
                "icon": "bx bx-mobile-alt",
                "level1": [
                    {
                        "label": "Create Games", "icon": "bxs-grid-alt",
                        "link": "/form?feature=games&index=0&main=1&level=0",
                        form: [
                            { datatype: "text", value: "requestType", label: "Request Type" },
                            { datatype: "text", value: "gameID", label: "Game" },
                            { datatype: "textarea", value: "respMsg", label: "Response Msg" },
                            { datatype: "text", value: "menuOrder", label: "Menu Order" },
                        ], endpoint: '/ussdMenu',
                        actions: []
                    },
                    {
                        "label": "USSD Menu",
                        "icon": "bxs-grid-alt",
                        "link": "/grid?feature=games&index=0&main=1&level=1",
                        form: [
                            { datatype: "text", value: "id", label: "ID" },
                            { datatype: "text", value: "requestType", label: "Request Type" },
                            { datatype: "text", value: "gameID", label: "Game" },
                            { datatype: "text", value: "respMsg", label: "Response Msg" },
                            { datatype: "text", value: "menuOrder", label: "Menu Order" },
                        ],
                        endpoint: '/ussdMenu',
                        actions: ["edit", "delete"]
                    }
                ]
            }
        ]
    }, {
        "label": "Others",
        "subs": [
            {
                "label": "Win Selections",
                "icon": "bx bx-mobile-alt",
                "level1": [
                    {
                        "label": "Create Win Selections", "icon": "bxs-grid-alt",
                        "link": "/form?feature=winSelection&index=0&main=2&level=0",
                        form: [
                            { datatype: "text", value: "name", label: "Name" },
                        ], endpoint: '/winSelection',
                        actions: ["edit", "delete"]
                    },
                    {
                        "label": "Manage Win Selections", "icon": "bxs-grid-alt",
                        "link": "/grid?feature=winSelection&index=0&main=2&level=1",
                        form: [
                            { datatype: "text", value: "id", label: "ID" },
                            { datatype: "text", value: "name", label: "Name" },
                        ],
                        endpoint: '/winSelection',
                        actions: ["edit", "delete"]
                    }
                ]
            },
            {
                "label": "Ticket Status",
                "icon": "bx bx-mobile-alt",
                "level1": [
                    {
                        "label": "Create Ticket Status", "icon": "bxs-grid-alt",
                        "link": "/form?feature=winSelection&index=1&main=2&level=0",
                        form: [
                            { datatype: "text", value: "name", label: "Name" },
                        ], endpoint: 'ticketStatus',
                        actions: ["edit", "delete"]
                    },
                    {
                        "label": "Manage Ticket Status", "icon": "bxs-grid-alt",
                        "link": "/grid?feature=winSelection&index=1&main=2&level=1",
                        form: [
                            { datatype: "text", value: "id", label: "ID" },
                            { datatype: "text", value: "name", label: "Name" },
                        ],
                        endpoint: 'ticketStatus',
                        actions: ["edit", "delete"]
                    }
                ]
            }
        ]
    },
    {
        "label": "Log Analysis",
        "subs": [
            {
                "label": "Logs",
                "icon": "bx bx-mobile-alt",
                "level1": [
                    {
                        "label": "View Logs", "icon": "bxs-grid-alt",
                        "link": "/grid?feature=winSelection&index=0&main=3&level=0",
                        form: [
                            { datatype: "text", value: "date", label: "Date" },
                            { datatype: "text", value: "file_name", label: "File" },
                        ],
                        endpoint: '/logs',
                        actions: ["viewlog"]
                    }
                ]
            }
        ]
    },
    // {
    //     "label": "Odds",
    //     "subs": [
    //         {
    //             "label": "Odds",
    //             "icon": "bx bx-analysis",
    //             "level1": [
    //                 {
    //                     "label": "View Odds", "icon": "bxs-grid-alt",
    //                     "link": "/grid?feature=winSelection&index=0&main=4&level=0",
    //                     form: [
    //                         { datatype: "text", value: "date", label: "Date" },
    //                         { datatype: "text", value: "file_name", label: "File" },
    //                     ],
    //                     endpoint: '/odds',
    //                     actions: ["viewlog"]
    //                 }
    //             ]
    //         }
    //     ]
    // }
]

export const formFields = (endpoint: string) => {
    if (endpoint?.startsWith('/games'))
        return ([
            { datatype: "text", value: "name", label: "Game Name", prefix: "" },
            { datatype: "number", value: "minStake", label: "Minimum Stake", prefix: "GHS " },
            { datatype: "number", value: "maxStake", label: "Maximum Stake", prefix: "GHS " },
            { datatype: "number", value: "minNumber", label: "Minimum Number" },
            { datatype: "number", value: "maxNumber", label: "Maximum Number" },
            { datatype: "text", value: "drawFrequency", label: "Draw Frequency" },
            { datatype: "text", value: "cronSchedule", label: "Cron Schedule" },
            { datatype: "number", value: "payoutThreshold", label: "Payout Threshold" },
            { datatype: "number", value: "multiplier", label: "Multiplier" },
            {
                datatype: "select", value: "winSelection", label: "Win Selection", "source": "link",
                "url": "/winSelection", keys: ["id", "name"]
            },
            { datatype: "text", value: "callbackUrl", label: "Callback Url" },
            { datatype: "text", value: "paymentGateway", label: "Payment Gateway Url" },
            { datatype: "number", value: "splitBlocks", label: "Split Blocks" },
            { datatype: "number", value: "displayOrder", label: "Display Order" },
            { datatype: "text", value: "shortcode", label: "ShortCode" },
            { datatype: "text", value: "game_header", label: "Game Header" },
            { datatype: "text", value: "sms_sender", label: "SMS Sender" },
            {
                datatype: "select", value: "status", label: "Status", "source": "object",
                "url": "", data: [
                    { value: 0, label: 'Inactive' },
                    { value: 1, label: 'Active' }
                ], keys: ["id", "value"]
            },
            {
                datatype: "select", value: "wallet", label: "Wallet", "source": "link",
                "url": "/wallets", keys: ["id", "name"]
            },
        ])
    else if (endpoint?.startsWith('/roles'))
        return ([
            { datatype: "text", value: "name", label: "Role Name", prefix: "" },
            { datatype: "text", value: "description", label: "Role Description", prefix: "" },
        ])
    else if (endpoint?.startsWith('roles/assignment'))
        return ([
            {
                datatype: "select", value: "role_id", label: "Role", "source": "link",
                "url": "/roles", keys: ["id", "name"]
            },
            {
                datatype: "select", value: "access_id", label: "Access", "source": "link",
                "url": "/accesses", keys: ["id", "label"]
            },
        ])
    else if (endpoint?.startsWith('accesses'))
        return ([
            { datatype: "text", value: "label", label: "Access Label", prefix: "" },
            {
                datatype: "select", value: "parent", label: "Parent Menu", "source": "link",
                "url": "/accesses", keys: ["id", "label"]
            },
            { datatype: "text", value: "endpoint", label: "Endpoint", prefix: "" },
            { datatype: "text", value: "link", label: "Link", prefix: "" },
            { datatype: "text", value: "actions", label: "Actions", prefix: "" },
        ])
    else if (endpoint?.startsWith('assignment'))
        return ([
            {
                datatype: "select", value: "role_id", label: "Role", "source": "link",
                "url": "/roles", keys: ["id", "name"]
            },
            {
                datatype: "select", value: "access_id", label: "Access", "source": "link",
                "url": "/accesses", keys: ["id", "label"]
            }
        ])
    else if (endpoint?.startsWith('admin'))
        return ([
            { datatype: "text", value: "name", label: "Full Name", prefix: "" },
            { datatype: "text", value: "email", label: "Email Address", prefix: "" },
            { datatype: "text", value: "mobile", label: "Mobile Number", prefix: "" },
            {
                datatype: "select", value: "status", label: "Status", "source": "object",
                "url": "", data: [
                    { value: 0, label: 'Inactive' },
                    { value: 1, label: 'Active' }
                ], keys: ["id", "value"]
            },
            { datatype: "text", value: "games", label: "Games eg. 1,2,3 or all", prefix: "" },
            { datatype: "password", value: "password", label: "Password", prefix: "" },
            {
                datatype: "select", value: "role_id", label: "Role", "source": "link",
                "url": "/roles", keys: ["id", "name"]
            }
        ])
    else if (endpoint?.startsWith('smstemplate'))
        return ([
            { datatype: "text", value: "gameID", label: "Game ID", prefix: "" },
            { datatype: "text", value: "smsType", label: "SMS Type", prefix: "" },
            { datatype: "textarea", value: "template", label: "Content", prefix: "" }
        ])
    else if (endpoint?.startsWith('tickets1'))
        return ([
            { datatype: "text", value: "id", label: "Ticket ID" },
            { datatype: "text", value: "mobileNumber", label: "Mobile number" },
            { datatype: "text", value: "status", label: "status" },
            { datatype: "text", value: "playedNumbers", label: "Played Numbers", },
            { datatype: "text", value: "stake", label: "Stake", prefix: "GHS " },
            { datatype: "text", value: "payoutAmount", label: "Payout", prefix: "GHS " },
            { datatype: "date", value: "playTimestamp", label: "Timestamp" },
            { datatype: "text", value: "mobileNetwork", label: "Network" },
        ])
    else if (endpoint?.startsWith('draws'))
        return ([
            { datatype: "text", value: "id", label: "Draw ID" },
            { datatype: "text", value: "winningNumbers", label: "Winning number" },
            { datatype: "text", value: "collections", label: "Collections" },
            { datatype: "text", value: "drawCollections", label: "Draw Collections" },
            { datatype: "text", value: "totalPlays", label: "Total Plays" },
            { datatype: "date", value: "drawTimestamp", label: "Timestamp" },
            { datatype: "text", value: "totalPayout", label: "Total Payout" },
            { datatype: "text", value: "payoutCount", label: "Payout Count" },
            { datatype: "text", value: "drawPayout", label: "Draw Payout" },
        ])
    else if (endpoint?.startsWith('ussdMenu'))
        return ([
            { datatype: "text", value: "id", label: "ID" },
            { datatype: "text", value: "requestType", label: "Request Type" },
            { datatype: "text", value: "gameID", label: "Game" },
            { datatype: "text", value: "respMsg", label: "Response Msg" },
            { datatype: "text", value: "menuOrder", label: "Menu Order" },
        ])
    else
        return ([])
}


export const formatAmt = (amount: number) => {
    return new Intl.NumberFormat('en-GH', { style: 'currency', currency: 'GHS' }).format(Number(amount));
}