import { FC, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { dataHttp } from "../HttpCon";

const Profile = () => {
    const user = JSON.parse(localStorage.getItem("ncarta_user") || '{}');
    const form = useForm({
        defaultValues: user
    });
    const [reset, setReset] = useState<any>({});
    const onSubmit = (data: any) => console.log(data);
    const { register, handleSubmit, formState: { isDirty, isSubmitting, isValid } } = form;
    return (<div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Account Settings /</span> Account</h4>

        <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <h5 className="card-header">Profile Details</h5>
                    <div className="card-body">
                        <form id="formAccountSettings" method="POST" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label className="form-label">First Name</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="name"
                                        {...register('name', { required: true })}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label">E-mail</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="email"
                                        {...register('email', { required: true })}
                                        placeholder="john.doe@example.com"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label">Mobile Number</label>
                                    <div className="input-group input-group-merge">
                                        <span className="input-group-text">GHS (+233)</span>
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            {...register('mobile', { required: true })}
                                            className="form-control"
                                            placeholder="0202 555 0111"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <h5 className="card-header">Delete Account</h5>
                    <div className="card-body">

                        {reset?.errors && <div className={`alert alert-danger`} role="alert">{reset?.errors}</div>}

                        <form id="formAccountDeactivation" onSubmit={() => { }} className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label">New Password</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="email"
                                    value={reset?.password}
                                    onChange={(e) => setReset((prev: any) => ({ ...prev, password: e.target.value }))}
                                />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label">Confirm Password</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="email"
                                    value={reset?.confirmpassword}
                                    onChange={(e) => setReset((prev: any) => ({ ...prev, confirmpassword: e.target.value }))}
                                />
                            </div>

                            <div className="mb-3 col-auto">
                                {reset?.loading === true ?
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> :
                                    <button type="button" className="btn btn-primary deactivate-account"
                                        onClick={() => {
                                            setReset((prev: any) => ({ ...prev, errors: '' }))
                                            if (reset?.password && reset?.password === reset?.confirmpassword) {
                                                setReset((prev: any) => ({ ...prev, loading: true }))
                                                dataHttp(
                                                    'admin/reset',
                                                    {
                                                        password: reset?.password
                                                    },
                                                    false,
                                                    'PUT').then((result: any) => {
                                                        setReset({});
                                                    }).catch(err => {

                                                    }).finally(() => {
                                                        setReset((prev: any) => ({ loading: false }))
                                                    });
                                            } else {
                                                setReset((prev: any) => ({ ...prev, errors: !reset?.password ? 'Password is required' : 'Passwords do not match' }))
                                            }
                                        }}>Change Password</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Profile;