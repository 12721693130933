import { FC, useEffect, useState } from "react";
import { noDataHttp } from "../HttpCon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccesses, setGames, setSelectList, setUser } from "../Reducers/userSlice";

const TopMenu: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDefault = {
        name: '',
        mobile: ''
    }
    const [users, setUsers_] = useState(userDefault);
    const [games, setGames_] = useState([]);
    const [game, setGame] = useState("1");

    useEffect(() => {
        setUsers_(JSON.parse(localStorage.getItem("ncarta_user") || '{}'));
        getGames();
        setGame(localStorage.getItem("activegame") || "1");
    }, []);

    const getGames = () => {
        noDataHttp(`games`, "GET").then((results: any) => {
            setGames_(results.message);
        }).finally(() => {
        });
    }
    console.log(games)

    return (<nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
    >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a className="nav-item nav-link px-0 me-xl-4" href="#">
                <i className="bx bx-menu bx-sm"></i>
            </a>
        </div>

        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

            <div className="navbar-nav align-items-center">
                <div className="nav-item d-flex align-items-center">
                    <i className="bx bx-data fs-4 lh-0"></i>
                    <select
                        className="form-control border-0 shadow-none"
                        placeholder="Search..."
                        aria-label="Search..."
                        value={game}
                        onChange={(e) => {
                            localStorage.setItem("activegame", String(e.target.value));
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        }}
                    >
                        {games?.filter((f: any) => ![99].includes(f.id))?.map((g: any, index: number) => <option value={g.id} key={`games${index}`}>{g.name}</option>)}
                    </select>
                </div>
            </div>

            <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="nav-item lh-1 me-3">
                    <a
                        className="github-button"
                        href=""
                        data-icon="octicon-star"
                        data-size="large"
                        data-show-count="true"
                        aria-label="Star themeselection/sneat-html-admin-template-free on GitHub"
                    >Welcome {users?.name}</a>
                </li>


                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                    <a className="nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
                        <div className="avatar avatar-online">
                            <img src="../assets/img/avatars/1.png" className="w-px-40 h-auto rounded-circle" />
                        </div>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <a className="dropdown-item" href="#">
                                <div className="d-flex">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar avatar-online">
                                            <img src="../assets/img/avatars/1.png" className="w-px-40 h-auto rounded-circle" />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <span className="fw-semibold d-block">{users?.name}</span>
                                        <small className="text-muted">{users?.mobile}</small>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <div className="dropdown-divider"></div>
                        </li>
                        <li>
                            <a className="dropdown-item" onClick={() => navigate('/profile')}>
                                <i className="bx bx-user me-2"></i>
                                <span className="align-middle">My Profile</span>
                            </a>
                        </li>
                        <li>
                            <div className="dropdown-divider"></div>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={() => {
                                localStorage.removeItem("ncarta_token");
                                localStorage.removeItem("ncarta_loggedIn");
                                localStorage.removeItem("ncarta_user");
                                localStorage.removeItem("activegame");
                                window.location.href = "/";
                                dispatch(setGames([]));
                                dispatch(setAccesses([]));
                                dispatch(setSelectList({}));
                                dispatch(setUser({}));
                            }}>
                                <i className="bx bx-power-off me-2"></i>
                                <span className="align-middle">Log Out</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>);
}

export default TopMenu;