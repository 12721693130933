import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Index from './pages/Index';
import Home from './pages/Home';
import Grid from './pages/Grid';
import Form from './pages/Form';
import Edit from './pages/Edit';
import Layout from './components/Layout';
import Profile from './pages/Profile';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RequireAuth><Index /></RequireAuth>} />
        <Route element={<NotLoggedIn><Layout /></NotLoggedIn>}>
          <Route path="/home" element={<Home />} />
          <Route path="/grid" element={<Grid />} />
          <Route path="/form" element={<Form />} />
          <Route path="/edit/:id" element={<Edit />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }: any) {
  const location = useLocation();
  if (localStorage.getItem("ncarta_token") !== null) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return children;
}

function NotLoggedIn({ children }: any) {
  const location = useLocation();
  if (localStorage.getItem("ncarta_token") === null) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
