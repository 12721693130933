import { Outlet } from "react-router-dom";
import { AddLibrary } from "../HttpCon";
import Aside from "./Aside";
import Footer from "./Footer";
import TopMenu from "./TopMenu";
import { ToastContainer } from "react-toastify";

const Layout = () => {
    return (<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">

                <Aside />

                <div className="layout-page" style={{ minHeight: '100vh' }}>

                    <TopMenu />

                    <div className="content-wrapper">
                        <Outlet />
                        <Footer />

                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>

            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        {AddLibrary('../assets/js/dashboards-analytics.js')}
    </>
    );
}

export default Layout;