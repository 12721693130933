
import React, { Fragment } from 'react';
import Spinner from './Spinner';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
    background: {
        default: '#fff',
    },
    context: {
        background: '#fff',
        text: '#000',
    },
    divider: {
        default: '#ddd',
    },
}, 'light');
const customStyles = {
    rows: {
        style: {
            minHeight: '72px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '0px',
            paddingTop: '10px',
            paddingBottom: '10px',
            fontWeight: "bold",
            backgroundColor: '#ededed',
            borderRight: '1px solid #fdfdfd',
            color: 'rgb(105, 122, 141)'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '13px',
            paddingBottom: '13px',
            color: 'rgb(105, 122, 141)'
        },
    },
};

const DataGrid = (props: any) => {
    return (
        <Fragment>
            <DataTable
                pagination
                theme="solarized"
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                dense
                {...props}
                striped={true}
                progressComponent={<Spinner />}
            />
        </Fragment>
    );
}

export default DataGrid;