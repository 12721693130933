import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        games: [],
        wallets: [],
        accesses: [],
        selectList: {},
        user: {}
    },
    reducers: {
        setGames: (state, action) => {
            state.games = action.payload
        },
        setWallets: (state, action) => {
            state.wallets = action.payload
        },
        setAccesses: (state, action) => {
            state.accesses = action.payload
        },
        setSelectList: (state, action) => {
            state.selectList = { ...state.selectList, ...action.payload }
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
    }
});

export const { setGames, setWallets, setAccesses, setSelectList, setUser } = userSlice.actions;
export default userSlice.reducer;