import axios from "axios";

const baseURL = 'https://admin-api.ncartabox.com/api/v1.0/admin/';
const http = axios.create({
    baseURL: baseURL
});

http.interceptors.request.use(
    async (config) => {
        const token = JSON.parse(localStorage.getItem('ncarta_token') || '{}').access || null;
        if (token) {
            config.headers['Authorization'] = token;

            const activegame = localStorage.getItem('activegame') || '';

            if (activegame)
                config.headers['activegame'] = activegame;
            else {
                const user = JSON.parse(localStorage.getItem('ncarta_user') || '{}') || null;

                let games = '';
                if (user?.games === 'all')
                    games = '1';
                else {
                    games = user?.games?.split(",")?.[0];
                }
                if (games) {
                    localStorage.setItem('activegame', games);
                    config.headers['activegame'] = games;
                } else {
                    localStorage.removeItem("ncarta_token");
                    localStorage.removeItem("ncarta_loggedIn");
                    localStorage.removeItem("ncarta_user");
                    window.location.href = "/";
                }
            }
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(async response => {
    if (response?.data?.code === 401) {
        const originalRequest = response.config;
        console.log("here")
        localStorage.removeItem("ncarta_token");
        localStorage.removeItem("ncarta_loggedIn");
        localStorage.removeItem("ncarta_user");
        window.location.href = "/";
        // originalRequest._retry = true;

        // const token = JSON.parse(localStorage.getItem('ncarta_token') || '{refresh:""}').refresh || '';
        // const access_token = await axios.post(baseURL + 'refresh', { refresh: token });

        // if (access_token?.status === 200) {
        //     token.access = access_token?.data;
        //     localStorage.setItem("ncarta_token", JSON.stringify(token));
        //     originalRequest.headers.Authorization =
        //         access_token?.data;
        // }
        // return http(originalRequest);
    }

    return response;
});

export default http;