import { combineReducers, configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session'

import user from './userSlice'
import persistStore from 'redux-persist/es/persistStore';
const persistConfig = {
    key: 'instacash',
    blacklist: [],
    storage: storageSession,
};

const rootReducer = combineReducers({
    user
});

const persistReducers = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistReducers,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);
export default store;