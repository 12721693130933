import moment from "moment";
import { FC } from "react";
import { mockComponent } from "react-dom/test-utils";
import { AddLibrary } from "../HttpCon";

const Footer: FC = () => {
    return (<><footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
                ©

                Ncarta Limited. All rights reserved
            </div>
            <div>

            </div>
        </div>

    </footer>
        {AddLibrary('../assets/vendor/js/menu.js')}
    </>);
}

export default Footer;