import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";
import DataTable from 'react-data-table-component';
import { formFields, menu } from "../Config";

interface Props {

}

interface DataPayload {
    label: string
}

const Index: FC<Props> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [noteType, setNoteType] = useState('alert-danger');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [endpoint, setEndpoint] = useState("");
    const [id, setID] = useState("");
    const [gameList, setGameList] = useState<object[]>([]);
    const [payload, setPayload] = useState<{ [key: string]: any }>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<{ [key: string]: any | undefined }>({});
    const [forms, setForms] = useState([
        { label: '', value: '', datatype: '' }
    ]);
    const [selectSource, setSelectSource] = useState<{ [key: string]: any }>({});

    const loadData = () => {
        const stateData: any = location?.state;
        const form: any = formFields(stateData?.endpoint);
        setID(String(params?.id));

        setForms(form);
        setEndpoint(stateData?.endpoint);

        setData(data);
        getGames();
    }


    const checkForSelect = async (form: any) => {
        const selectData: { [key: string]: any } = {};
        await Promise.all(
            form.filter((f: any) => f.datatype === "select")
                .map(async (d: any) => {
                    return await new Promise((resolve) => {
                        if (d.source === "link") {
                            noDataHttp(d.url).then((results: any) => {
                                console.log("called 1::", results)
                                if (results.code === 200) {
                                    resolve({
                                        key: d?.value, data: results.message.map((i: any) => {
                                            return {
                                                label: i[d.keys[1]],
                                                "value": i[d.keys[0]]
                                            };
                                        })
                                    });
                                } else
                                    resolve({ key: d?.value, data: [] });
                            }).catch(err => {
                            }).finally(() => {
                                setLoading(false);
                            });
                        } else if (d.source === 'object') {
                            resolve({ key: d?.value, data: d.data });
                        }
                    })
                })).then(async data => {
                    await data.map(m => {
                        selectData[m?.key] = m?.data;
                    })

                    setSelectSource(selectData);
                }).catch(err => {
                })
    }

    const handleChange = (key: string, value: string) => {
        let currentPayload: { [key: string]: any } = {};
        currentPayload[key] = value;
        setPayload({ ...payload, ...currentPayload });
    }

    const getData = async () => {
        setLoading(true);
        noDataHttp(`${endpoint}/${params?.id}`).then((results: any) => {
            if (results.code === 200) {
                let pLoad = payload;
                Object.keys(results.message).map(res => pLoad[res] = results.message[res]);
                setPayload(prev => ({ ...prev, ...pLoad }));
            }
        }).catch(err => {

        }).finally(() => {
            setLoading(false);
        });
    }


    const submitForm = () => {
        setLoading(true);
        setNoteType("alert-danger");
        dataHttp(
            `${endpoint}/${id}`,
            payload,
            true,
            'PUT').then((result: any) => {
                console.log(result);
                if (result?.errors) {
                    setError(result?.errors.map((err: any) => <p>{`${err.msg}`}</p>));
                } else {
                    setNoteType("alert-success");
                    setError(result?.message || result);
                }
            }).catch(err => {
                // setError(err);
            }).finally(() => {
                setLoading(false);
            });
    }

    const getGames = () => {
        noDataHttp('games').then((results: any) => {
            if (results.code === 200) {
                setGameList(results.message);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [location]);

    useEffect(() => {
        getData();
    }, [endpoint]);

    useEffect(() => {
        checkForSelect(forms);
    }, [forms]);

    useEffect(() => {
        const stateData: any = location?.state;
        const form: any = formFields(stateData?.endpoint);
        setID(String(params?.id));

        setForms(form);
    }, [payload]);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>{data.label}</h4>

            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5 className="mb-0">Update {data.label}</h5>
                            {/* <small className="text-muted float-end">Default label</small> */}
                        </div>
                        <div className="card-body">
                            {error !== "" && <div className={`alert ${noteType}`} role="alert">{error}</div>}
                            <form>
                                <div className="row mb-3 col-12">
                                    {forms.map(f => {
                                        if (!["id", 'password'].includes(f.value)) {

                                            let input = <div className="mb-3 col-6">
                                                <label className="form-label" >{f.label}</label>
                                                <input type={f.datatype}
                                                    className="form-control"
                                                    id="basic-default-fullname"
                                                    onChange={(e) => handleChange(f.value, e.target.value)}
                                                    value={payload[f.value]} />
                                            </div>;

                                            if (f.datatype === "select") {
                                                let option: any = [];
                                                option.push(<option value="" disabled selected>Select {f?.label}</option>);
                                                selectSource?.[f?.value]?.map((op: any) => { option.push(<option value={op.id}>{op.name}</option>); });
                                                input = <div className="mb-3 col-6">
                                                    <label className="form-label" >{f.label}</label>
                                                    <select
                                                        className="form-control"
                                                        id="basic-default-fullname"
                                                        onChange={(e) => handleChange(f.value, e.target.value)}
                                                        value={payload[f.value]} >
                                                        {option}
                                                    </select>
                                                </div>;
                                            }

                                            if (f.value === "gameID") {
                                                let option: any = [];
                                                option.push(<option value="" disabled selected>Select Game</option>);
                                                gameList.map((op: any) => { option.push(<option value={op.id}>{op.name}</option>); });
                                                input = <div className="mb-3 col-6">
                                                    <label className="form-label" >{f.label}</label>
                                                    <select
                                                        className="form-control"
                                                        id="basic-default-fullname"
                                                        onChange={(e) => handleChange(f.value, e.target.value)}
                                                        value={payload[f.value]} >
                                                        {option}
                                                    </select>
                                                </div>;
                                            }

                                            if (selectSource[f.value]) {
                                                let option: any = [];
                                                option.push(<option value="" disabled selected>Select {f.value}</option>);
                                                selectSource[f.value].map((op: any) => { option.push(<option value={op.value}>{op.label}</option>); });
                                                input = <div className="mb-3 col-6">
                                                    <label className="form-label" >{f.label}</label>
                                                    <select
                                                        className="form-control"
                                                        id="basic-default-fullname"
                                                        onChange={(e) => handleChange(f.value, e.target.value)}
                                                        value={payload[f.value]} >
                                                        {option}
                                                    </select>
                                                </div>;
                                            }
                                            if (f.datatype === "textarea") {
                                                input = <div className="mb-3 col-12">
                                                    <label className="form-label" >{f.label}</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="basic-default-fullname"
                                                        style={{ height: 120 }}
                                                        defaultValue={payload[f.value]}
                                                        onChange={(e) => handleChange(f.value, e.target.value)}></textarea>
                                                </div>;
                                            }

                                            return (input);
                                        }
                                    })}
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-sm-12">
                                        {loading === true ?
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : <button type="button" onClick={() => submitForm()} className="btn btn-primary">Update {data.label}</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;