import http from "./http";

const baseUrl = "https://admin-api.ncartabox.com/api/v1.0/admin/";
// const baseUrl = "http://localhost:28010/api/v1.0/admin/";

export const noDataHttp = async (url: string, methodType: string = "GET") => {
    // return await new Promise((resolve, reject) => {
    //     const token = JSON.parse(localStorage.getItem('ncarta_token') || '{access:""}').access || '';
    //     const activegame = localStorage.getItem('activegame') || '1';
    //     console.log('activegame::', activegame);
    //     const requestHeaders: HeadersInit = new Headers();
    //     requestHeaders.set('Content-Type', 'application/json');
    //     requestHeaders.set('Authorization', token);
    //     requestHeaders.set('activegame', activegame);

    //     fetch(`${baseUrl}${url}`, {
    //         method: methodType,
    //         headers: requestHeaders,
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             if (response.code === 401) {
    //                 localStorage.removeItem("ncarta_token");
    //                 localStorage.removeItem("ncarta_loggedIn");
    //                 localStorage.removeItem("ncarta_user");
    //                 window.location.href = "/";
    //             }
    //             resolve(response);
    //         })
    //         .catch(error => {
    //             // reject(error.getMessage);
    //         })
    // });
    return await new Promise((resolve, reject) => {
        http(url, {
            method: methodType
        }).then(results => {
            resolve(results?.data?.code ? results?.data : { code: 200, message: results?.data });
        }).catch(err => {
            console.log(err)
            reject({ code: err?.response?.status || 400, message: err?.response?.data || '' });
        });
    });
}

export const dataHttp = async (urlPath: string, data: Object, auth: boolean, methodType: string) => {
    // return await new Promise((resolve, reject) => {
    //     const requestHeaders: HeadersInit = new Headers();
    //     requestHeaders.set('Content-Type', 'application/json');

    //     if (auth == true) {
    //         const token = JSON.parse(localStorage.getItem('ncarta_token') || '{access:""}').access || '';
    //         const activegame = localStorage.getItem('activegame') || '1';
    //         console.log('activegame::', activegame);

    //         requestHeaders.set('activegame', activegame);
    //         requestHeaders.set('Authorization', token);
    //     }
    //     console.log('-----', urlPath);

    //     fetch(`${baseUrl}${urlPath}`, {
    //         method: methodType,
    //         headers: requestHeaders,
    //         body: JSON.stringify(data),
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             if (response.code === 401) {
    //                 localStorage.removeItem("ncarta_token");
    //                 localStorage.removeItem("ncarta_loggedIn");
    //                 localStorage.removeItem("ncarta_user");
    //                 window.location.href = "/";
    //             }
    //             resolve(response);
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             // reject(error.getMessage);
    //         })
    // });
    return await new Promise((resolve, reject) => {
        http(urlPath, {
            method: methodType,
            data: data || {},
        }).then(results => {
            console.log(':::', results?.data)
            resolve(results?.data);
        }).catch(err => {
            console.log(':::==', err)
            reject({ code: err?.response?.status || 400, message: err?.response?.data || '' });
        });
    });
}


export function AddLibrary(urlOfTheLibrary: string) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}